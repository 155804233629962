import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
// import StargazerLogo from "../images/StargazerLabsLogo.svg";
import StargazerLogo from "../images/StargazerOpsLogo.svg";
import clouds from "../images/header.jpg";
import AesunaCircleLogo from "../images/AesunaCircleArtboardP.png";
import swyftmdlogo from "../images/Artboard 1.svg";
import matterhorn from "../images/matterhorn.jpg";
import angled from "../images/angled-background-fix.svg";
import Hero from "../components/Hero";
import Features from "../components/Features";
import CTA from "../components/CTA";
import Pricing from "../components/Pricing";
import FullFeatures from "../components/FullFeatures";

const BG_IMG_URL = `url(${matterhorn})`;

const maebe = `url(${angled})`;
// "url(../images/matterhorn.jpg)";
// "url(https://images.unsplash.com/photo-1491555103944-7c647fd857e6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)";
// "url(https://images.unsplash.com/photo-1521490918262-b83aee4029e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3264&q=80)";
// "url(https://images.unsplash.com/photo-1519331301497-ba35b6a588cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3000&q=80)";
// "url(https://images.unsplash.com/photo-1504276288612-c18c8df55e05?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80)";
// "url(https://images.unsplash.com/photo-1529000920031-b8f994d2eb27?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80)";

const IndexPage = () => (
  <Layout>
    <SEO title="SwyftDoc" />

    <Hero />
    {/* <Features /> */}
    <FullFeatures />
    <Pricing />
    <CTA />
  </Layout>
);

export default IndexPage;
