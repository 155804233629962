import React, { useState } from "react";
import SwyftDocLogo from "../images/SwyftDocLogo.svg";
import DoctorImage from "../images/doctors/Stocksy_woman.jpg";
import GreenCheckMark from "../components/GreenCheckmark";
import { Link } from "gatsby";
import { PATIENT_WEBSITE } from "../constants";

export default function Hero() {
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = trackingTitleString => {
    window.analytics.track(trackingTitleString);
  };
  return (
    <div
      // x-data="{ open: false }"
      className="relative overflow-hidden bg-white"
    >
      <div className="max-w-screen-xl mx-auto ">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <div className="px-4 pt-6 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <img
                      className="w-auto h-8 sm:h-10"
                      src={SwyftDocLogo}
                      alt="SwyftDoc Logo"
                    />
                  </Link>
                  <div className="flex items-center -mr-2 md:hidden">
                    <button
                      //   @click="open = true"
                      type="button"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        // console.log("bitchaaa")
                      }}
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4">
                <Link
                  to="/howitworks"
                  className="font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  How it works
                </Link>

                <Link
                  to="/whatwetreat"
                  className="ml-8 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  What we treat
                </Link>
                <a
                  onClick={() => {
                    handleClick("Hero->PatientWebsite");
                  }}
                  href={PATIENT_WEBSITE}
                  target="_blank"
                  className="ml-8 font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-900 focus:outline-none focus:text-indigo-700"
                >
                  Get started
                </a>
              </div>
            </nav>
          </div>

          <div
            // x-show="open"
            //  x-transition:enter="duration-150 ease-out"
            //   x-transition:enter-start="opacity-0 scale-95"
            //    x-transition:enter-end="opacity-100 scale-100"
            // x-transition:leave="duration-100 ease-in"
            //  x-transition:leave-start="opacity-100 scale-100"
            //   x-transition:leave-end="opacity-0 scale-95"
            className={
              isOpen
                ? "hidden"
                : "absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
            }
            // className="hidden"
          >
            <div className="rounded-lg shadow-md">
              <div className="overflow-hidden bg-white rounded-lg shadow-xs">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img
                      className="w-auto h-8"
                      // src="/img/logos/workflow-mark-on-white.svg"
                      src={SwyftDocLogo}
                      alt="SwyftDoc Logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      //   @click="open = false"
                      type="button"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        // console.log("bitch")
                      }}
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  <Link
                    to="/"
                    className="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    Home
                  </Link>
                  <Link
                    to="/howitworks"
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    How it works
                  </Link>
                  <Link
                    to="/whatwetreat"
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    What we treat
                  </Link>
                  {/* <a
                    href="#"
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  >
                    Company
                  </a> */}
                </div>
                <div>
                  <a
              href={PATIENT_WEBSITE}
              target="_blank"
                    onClick={() => {
                      handleClick("Hero->PatientWebsite");
                    }}
                    className="block w-full px-5 py-3 font-medium text-center text-indigo-600 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-screen-xl px-4 mx-auto mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="font-sans text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Speak with a doctor
                <br className="xl:hidden" />
                <span className="text-indigo-600">
                  {" "}
                  when and where you want
                </span>
              </h2>
              <div className="flex flex-row items-center justify-start mt-3 sm:mt-5 md:mt-3 ">
                <GreenCheckMark />
                <div className="pl-2 text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0">
                  Skip the waiting room! Online visits with world-class
                  physicians
                </div>
              </div>
              <div className="flex flex-row items-center justify-start mt-3 sm:mt-5 md:mt-4 ">
                <GreenCheckMark />
                <div className="pl-2 text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0">
                  Medical advice, prescriptions
                </div>
              </div>
              <div className="flex flex-row items-center justify-start mt-3 sm:mt-5 md:mt-4 ">
                <GreenCheckMark />
                <div className="pl-2 text-base text-gray-500 sm:text-lg sm:max-w-xl sm:mx-auto md:text-xl lg:mx-0">
                  From your phone, tablet or computer - any time, 24/7
                </div>
              </div>

              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    onClick={() => {
                      handleClick("Hero->PatientWebsite");
                    }}
                    href={PATIENT_WEBSITE}
                    target="_blank"
                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline md:py-4 md:text-lg md:px-10"
                  >
                    See a doctor
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#careanywhere"
                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-indigo-700 transition duration-150 ease-in-out bg-indigo-100 border border-transparent rounded-md hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 md:py-4 md:text-lg md:px-10"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={DoctorImage}
          //   src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
