import React from "react";
import { PATIENT_WEBSITE } from "../constants";
import { Link } from "gatsby";

export default function CTA() {
  const handleClick = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Hero Get Started button");
    }
  };

  return (
    <div class="bg-white">
      <div class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Take control of your health
          <br />
          Book an appointment with our physicians.
        </h2>
        <div class="mt-8 flex justify-center">
          <div
            onClick={() => {
              handleClick("Hero->PatientWebsite");
            }}
            class="inline-flex rounded-md shadow"
          >
            <a
              href={PATIENT_WEBSITE}
              target="_blank"
              id="heroGetStartedButton"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Get started
            </a>
          </div>
          {/* <div class="ml-3 inline-flex">
            <a
              href="#"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
            >
              Learn more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
