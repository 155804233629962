import React from "react";
import { Link } from "gatsby";
import { PATIENT_WEBSITE, VISIT_PRICE } from "../constants";

export default function Pricing() {
  return (
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-sans text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              Simple, no-tricks pricing
            </h2>
            <p className="mt-4 text-xl leading-7 text-gray-500">
              {/* 15 minute sessions */}
            </p>
          </div>
        </div>
      </div>
      <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 bg-gray-100 h-1/2"></div>
          <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
              <div className="px-6 py-8 bg-white lg:flex-shrink-1 lg:p-12">
                <h3 className="font-sans text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                  Affordable and transparent
                </h3>
                <p className="mt-6 text-base leading-6 text-gray-500">
                  SwyftDoc's physicians are available nationwide. It's free to
                  signup, and the price is always the same, so there are no
                  surprise bills later.
                </p>
                <div className="mt-8">
                  <div className="flex flex-row items-center ">
                    <h4 className="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-white">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200"></div>
                  </div>
                  <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-5 h-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-5 text-gray-700">
                        Video consultation over phone or web
                      </p>
                    </li>
                    <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-5 h-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-5 text-gray-700">
                        Prescriptions phoned to your pharmacy
                      </p>
                    </li>
                    <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-5 h-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-5 text-gray-700">
                        World class physicians
                      </p>
                    </li>
                    <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div className="flex-shrink-0">
                        <svg
                          className="w-5 h-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-5 text-gray-700">
                        Compassionate and trusted providers
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <p className="font-sans text-lg font-medium leading-6 text-gray-900">
                  Virtual Medical Visit
                </p>
                <div className="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900">
                  <span>{VISIT_PRICE}</span>
                  <span className="ml-3 text-xl font-medium leading-7 text-gray-500">
                    USD
                  </span>
                </div>
                {/* <p className="mt-4 text-sm leading-5">
                  <a href="#" className="font-medium text-gray-500 underline">
                    Learn about our membership policy
                  </a>
                </p> */}
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      // href="#"
                      href={PATIENT_WEBSITE}
                      target="_blank"
                      onClick={() => {
                        window.analytics.track("ScheduleAppointmentButton");
                      }}
                      className="flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:shadow-outline"
                    >
                      Schedule Appointment
                    </a>
                  </div>
                </div>
                {/* <div className="mt-4 text-sm leading-5">
                  <a href="#" className="font-medium text-gray-900">
                    Get a free sample
                    <span className="font-normal text-gray-500">(20MB)</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
