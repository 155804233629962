import React from "react";
// import DoctorImage from "../images/doctors/woman_light_green.jpg";
import DoctorImage from "../images/doctors/Stocksy_man.jpg";

import DoctorImageTwo from "../images/doctors/smiling_woman_ipad.jpg";
// import Layout from "./layout";

export default function FullFeatures() {
  return (
    <div className="py-16 overflow-hidden bg-gray-50 lg:py-24">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg
          className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="svg-pattern-squares-1"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
        </svg>

        <div className="relative" id="careanywhere">
          <h3 className="font-sans text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
            A better way to receive care
          </h3>
          <p className="max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500">
            SwyftDoc's goals are to provide world class care wherever you are.
            Our US based, board-certified physicians are available whenever you
            need.
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h4 className="font-sans text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
              Care that focuses on you
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Whether you're sick or you need to see a doctor, our physicians
              are standing by. We focus on your issues and getting you back on
              track, as well as ordering prescriptions.
            </p>

            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="font-sans text-lg font-medium leading-6 text-gray-900">
                      Compassionate providers
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Our providers focus on creating a new relationship with
                      you by focusing on the details and what's important to
                      you.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      A hand picked network of doctors ready to help you.
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Friendly, board-certified doctors are revolutionizing
                      remote access to quality healthcare. They are
                      professionally trained to use virtual technology to treat
                      many non-emergency conditions.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg font-medium leading-6 text-gray-900">
                      Get your diagnoses and prescriptions.
                    </h5>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      SwyftDoc makes it easy to visit a doctor in minutes and
                      get any diagnoses and needed prescriptions.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="relative mt-10 -mx-4 lg:mt-0">
            <svg
              className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-2"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#svg-pattern-squares-2)"
              />
            </svg>
            <img
              className="relative mx-auto rounded-lg shadow-xl"
              width="490"
              src={DoctorImage}
              alt="Doctor smiling"
            />
          </div>
        </div>

        <svg
          className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="svg-pattern-squares-3"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#svg-pattern-squares-3)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h4 className="font-sans font-extrabold leading-8 tracking-tight text-gray-900 font-sanstext-2xl sm:text-3xl sm:leading-9">
                Care anywhere
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Whether you're on the go, or at home, SwyftDoc is available to
                you.
              </p>

              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                        <svg
                          className="w-6 h-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-medium leading-6 text-gray-900">
                        Appointment based
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Book an immediate appointment if available, or let us
                        know what time works for you. You'll receive an
                        immediate confirmation.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                        <svg
                          className="w-6 h-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-medium leading-6 text-gray-900">
                        Receive your video link via SMS and email, and see your
                        doctor
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Our appointments are conducted over Zoom. You'll receive
                        your room number shortly before your appointment by SMS
                        and email. The Zoom app is required which you can
                        download{" "}
                        <a
                          href="https://zoom.us/download"
                          className="text-indigo-500"
                        >
                          here.
                        </a>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
              <svg
                className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="svg-pattern-squares-4"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#svg-pattern-squares-4)"
                />
              </svg>
              <img
                className="relative mx-auto rounded-lg shadow-xl"
                width="490"
                src={DoctorImageTwo}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
